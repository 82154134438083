@import url('https://fonts.googleapis.com/css?family=Federant|Josefin+Sans');

$primary: #884d00;
/* MAIN COLOR */
$secondary: #c44141;
/* SECONDARY COLOR */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px;
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


h1,
h2,
h3 {
	font-family: 'Federant', cursive;
}

a {
	font-family: 'Federant', cursive;

}

p {
	font-family: 'Josefin Sans',
		sans-serif;
}

.flash {
	display: none;
}

nav {
	z-index: 1000;
	background-color: #fff;
}

@media (max-width: 1024px) {

	.row {
		margin-right: 0px;
		margin-left: 0px;
	}

}

.navbar-default {
	// background-image: url('../img/nav-bg.png');
	background-repeat: repeat-x;
	background-position: 50% 50%;
	
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	border-bottom: 5px solid $secondary;
	
	border-radius: 0px;



	@media (max-width: 767px) {
		background: $wht;
	}


}


.navbar-header {

	@media (max-width: 767px) {
		// background-image: url('../img/nav-bg.png');
		background-repeat: repeat-x;
		background-position: 50% 50%;
	}
}

.navbar .navbar-nav {
	>li>a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;
		color: $wht;
		text-shadow: 2px 2px $secondary;

		@media (max-width: 767px) {
			height: 25px;
			display: inline-block;
			text-shadow: none;
			color: $secondary;
		}

		&:hover,
		&:focus {
			background: transparent;
			color: $secondary;
			text-shadow: none;
		}
	}
}

.navbar-right {
	margin-top: 25px;
	font-size: 1.75em;

	@media (max-width: 767px) {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	@media (max-width: 1024px) {
		font-size: 1.5em;
		margin-top: 0px;
	}
}

.navbar-toggle {
	margin: 30px 15px 8px 0px;
	border: 1px solid transparent;
	border-radius: 4px;

	@media (max-width: 767px) {
		margin-top: 14px;
	}
}

.navbarFixed {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 999;
	background-color: rgba($blk, .5);
	margin-bottom: 0;
	margin-top: 0;

	@media(max-width: 991px) {
		background-color: rgba($blk, 1);

	}


}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary, 10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk, 10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {
		display: none;
	}

	.modal-content {
		color: $primary;

		h2 {
			text-align: center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary, 20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border: none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border: none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header,
.modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

i {
	font-size: 5em;
	color: $secondary;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	background-image: url('../img/footer-bg.png');
	background-repeat: repeat-x;
	background-position: 50% 50%;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username,
input#password {
	width: 100%;
}

@media (max-width: 1024) {

	input#username,
	input#password {
		font-size: 16px;
	}
}

select {
	-moz-appearance: none;
	text-indent: 0.01px;
	text-overflow: '';
}

/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
	background-color: $primary;
	border-color: $wht;
	color: $wht;
	padding: 10px 20px;
	font-size: 19px;
	text-transform: uppercase;
	border-radius: 12px;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-o-transition: all 1s;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.btn-download:hover {
	background-color: $secondary;
	color: $wht;

}

.btn-download {
	margin-bottom: 15px !important;
	display: block;
	margin: 0 auto;
	max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========== HEADER ============== */

.header {
	background-image: url('../img/banner.jpg');
	background-repeat: no-repeat;
	background-position: 50% 10%;
	background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;
	text-align: center;

	@media (max-width: 1199px) {
		background-position: 10% 20%;
	}

	p {
		font-size: 1.75em;
		color: $wht;

		@media (max-width: 767px) {
			font-size: 1.5em;
		}
	}

	h1 {
		color: $wht;
		font-size: 5em;
		text-align: center;
		text-shadow: 3px 3px $secondary;

		@media (max-width: 1023px) {
			font-size: 3em;
		}

		@media (min-width: 1024px) and (max-width: 1199px) {
			font-size: 4em;
		}
	}

	img {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 600px;

		@media (max-width: 1024px) {
			max-width: 320px;
		}
	}

}

.overlay {
	background-color: rgba(31, 31, 31, 0.5);
	padding: 200px 0px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	@media (max-width: 1024px) {
		padding: 150px 0;
	}

	@media (max-width: 767px) {
		padding: 100px 0;
	}

	@media (max-width: 500px) {
		padding: 70px 0;
	}
}

.logo {
	max-height: 100px;
	margin-left: 10px;
	padding: 5px;

	@media (max-width: 991px) {
		max-height: 70px;
	}
}


/*=========== Upper CTA ============ */

.upper-cta {
	padding: 150px 0px;
	background: $secondary;

	@media (max-width: 767px) {
		padding: 70px 0;
	}

	p {
		font-size: 2em;
		color: $wht;

		@media (max-width: 767px) {
			font-size: 1.5em;
		}

		@media (min-width:1024px) and (max-width: 1199px) {
			font-size: 2em;
		}
	}

	h1 {
		font-size: 4em;
		color: $wht;

		@media (max-width: 767px) {
			font-size: 2.5em;
		}
	}

}



/* ========== MINIFORM AREA ============== */

.miniform {
	padding: 50px;
	background-image: url('../img/backpattern.jpg');
	background-repeat: repeat;
	background-position: 60% 60%;
	background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-background-size: cover;

	@media (max-width: 1024px) {
		padding: 35px 0px;
		text-align: center;
	}

	img {
		width: 100%;
		display: block;
		margin: 0 auto;
		max-width: 650px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


		@media (max-width: 1024px) {
			margin-top: 25px;
		}
	}

	h1 {
		font-size: 4em;
		color: $secondary;
		padding: 10px;

		@media (max-width: 767px) {
			font-size: 2.5em;
		}
	}

	p {
		color: $primary;
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 40px;
		padding: 10px;

		@media (max-width: 767px) {
			font-size: 1.5em;
		}
	}
}

/* ========== BENEFITS ============== */

.benefits {
	background-image: url('../img/benefits.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;

	h1 {
		font-size: 3.5em;
		color: $wht;

		@media (max-width: 1024px) {
			font-size: 3em;
		}

		@media (min-width: 1024px) and (max-width: 1199px) {
			font-size: 3.5em;
		}
	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 40px;
		padding: 10px;
		color: $wht;
	}

	img {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		margin-bottom: 50px;

		@media (max-width: 767px) {
			margin-bottom: 20px;

		}
	}
}




/* ========== FEATURES ============== */


.features {
	padding: 150px 0;
	text-align: center;

	@media (max-width: 1024px) {
		padding: 60px 0;
	}

	h1 {
		font-size: 4em;
		color: $secondary;
		text-align: center;

		@media (max-width: 1024px) {
			font-size: 2.25em;
		}

		@media (min-width:1024px) and (max-width: 1199px) {
			font-size: 4em;
		}

	}

	h2 {
		font-size: 3em;
		color: $secondary;

		@media (max-width: 1024px) {
			text-align: center;
		}

		@media (min-width:1024px) and (max-width: 1199px) {
			font-size: 2em;
		}

	}

	p {
		font-size: 1.65em;
		letter-spacing: 1px;
		line-height: 30px;
		color: $primary;
		margin-bottom: 50px;

		@media (max-width: 1024px) {
			text-align: center;
		}

		@media (min-width:1024px) and (max-width: 1199px) {
			font-size: 1.35em;
		}
	}

	img {
		width: 100%;
		max-width: 250px;
		display: block;
		margin: 0 auto;
		@media (max-width: 1024px) {
			max-width: 250px;
		}
	}
}


.features .sticky-wrapper {
	height: auto !important;
}

.navbar {
	margin-bottom: 0px;
}

.top-pad {
	margin-top: 15rem;
}